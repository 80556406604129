import React from "react";
import { graphql } from "gatsby";
// import loadable from "@loadable/component";
import ProductDevelopment, { productFAQList } from "../components/ProductDevelopment/ProductDevelopment";
import Layout from "../components/Common/Layout/Layout";
import SEO from "../components/Common/SEO/SEO";

// const Layout = loadable(() => import("@common/Layout/Layout"));
// const ProductDevelopment = loadable(() =>
//     import("@components/ProductDevelopment/ProductDevelopment")
// );
// const SEO = loadable(() => import("@components/Common/SEO/SEO"));

const ProductDevelopmentPage = ({ path, data }) => (
    <>
        <SEO
            title="Full-cycle Software Product Development with an Experienced Team"
            titleTemplate="Impressit"
            description="Whenever you were left with a half-finished project or need to modernize existing product, Impressit team will assist you in software product development."
            path={path}
            faqSchema={productFAQList}
        />
        <Layout path={path} isNoPadding>
            <ProductDevelopment caseStudies={data.allStrapiCaseStudies} />
        </Layout>
    </>
);

export default ProductDevelopmentPage;

export const startProductDevelopmentQuery = graphql`
    query productDevelopmentAllCaseStudiesQuery {
        allStrapiCaseStudies {
            nodes {
                id
                logo {
                    url
                }
                wideImage {
                    url
                }
                metaUrl
                title
            }
        }
    }
`;
